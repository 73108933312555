/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
 * yarn icons:build
 */
import * as React from 'react';
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const BackgroundCheckedIcon = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 20"
        ref={ref}
        {...props}
    >
        <path
            fill="currentColor"
            d="M12.164 7.293a1 1 0 1 1 1.415 1.414L9.336 12.95a1 1 0 0 1-1.536-.148l-1.317-1.317a1 1 0 0 1 1.414-1.414l.745.745 3.522-3.523Z"
        />
        <path
            fill="currentColor"
            d="M17.278 4.312c-4.614-.24-6.649-2.991-6.668-3.018-.277-.392-.943-.391-1.218-.001-.02.028-2.055 2.778-6.668 3.02a.751.751 0 0 0-.541.28.837.837 0 0 0-.18.603c.053.527 1.338 12.93 7.888 13.795.02.004.089.009.11.009.015 0 .078-.005.093-.007 6.566-.867 7.85-13.27 7.903-13.8a.842.842 0 0 0-.18-.603.749.749 0 0 0-.539-.278Zm-6.29-1.814ZM10 3.535c1.063.94 2.973 2.208 5.85 2.642-.185 1.204-.561 3.166-1.25 5.128-.534 1.526-1.22 2.938-2.064 3.984-.784.97-1.618 1.526-2.536 1.695-.918-.165-1.754-.72-2.54-1.695-.843-1.045-1.527-2.457-2.062-3.983-.687-1.963-1.063-3.924-1.249-5.13C7.016 5.746 8.928 4.485 10 3.536Z"
        />
    </svg>
);
const ForwardRef = forwardRef(BackgroundCheckedIcon);
export default ForwardRef;
